<template>
	<div class="carousel-info">
		<div
			id="carouselExampleIndicators"
			class="carousel carousel-dark slide"
			data-bs-interval="false"
			data-bs-touch="true"
		>
			<div class="carousel-indicators">
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="0"
					class="active"
					aria-current="true"
					aria-label="Slide 1"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="1"
					aria-label="Slide 2"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="2"
					aria-label="Slide 3"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="3"
					aria-label="Slide 4"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="4"
					aria-label="Slide 5"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="5"
					aria-label="Slide 6"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="6"
					aria-label="Slide 7"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="7"
					aria-label="Slide 8"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="8"
					aria-label="Slide 9"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="9"
					aria-label="Slide 10"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="10"
					aria-label="Slide 11"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="11"
					aria-label="Slide 12"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="12"
					aria-label="Slide 13"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="13"
					aria-label="Slide 14"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="14"
					aria-label="Slide 15"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="15"
					aria-label="Slide 16"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="16"
					aria-label="Slide 17"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="16"
					aria-label="Slide 17"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="17"
					aria-label="Slide 18"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="18"
					aria-label="Slide 19"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="19"
					aria-label="Slide 20"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="20"
					aria-label="Slide 21"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="21"
					aria-label="Slide 22"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="22"
					aria-label="Slide 23"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="23"
					aria-label="Slide 24"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="24"
					aria-label="Slide 25"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="25"
					aria-label="Slide 26"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="26"
					aria-label="Slide 27"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="27"
					aria-label="Slide 28"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="28"
					aria-label="Slide 29"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="29"
					aria-label="Slide 30"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="30"
					aria-label="Slide 31"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="31"
					aria-label="Slide 32"
				></button>
				<button
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide-to="32"
					aria-label="Slide 33"
				></button>
			</div>
			<div class="carousel-inner">
				<div class="carousel-item active">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_0.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13_4.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13_4_xxxxxxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13_8.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13_9.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13_9_xx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_13_9_xxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_x.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-1.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-2.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-3.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-4.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-5.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-6.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-7.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-8.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-9.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-10.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-11.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-12.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-13.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-14.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-15.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-16.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-17.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-18.jpeg" class="d-block w-100" alt="..." />
				</div>
				<div class="carousel-item">
					<img src="../assets/medias/lbp-19.jpeg" class="d-block w-100" alt="..." />
				</div>
				<!-- <div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div> -->
				<!-- <div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div>
				<div class="carousel-item">
					<img
						src="../assets/Les_Belles_Plantes/Les_Belles_Plantes_FRK_378718_4_xxxxxx.jpg"
						class="d-block w-100"
						alt="..."
					/>
				</div> -->
			</div>
			<button
				class="carousel-control-prev"
				type="button"
				data-bs-target="#carouselExampleIndicators"
				data-bs-slide="prev"
			>
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Previous</span>
			</button>
			<button
				class="carousel-control-next"
				type="button"
				data-bs-target="#carouselExampleIndicators"
				data-bs-slide="next"
			>
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Next</span>
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: "CarouselDemo",
};
</script>
<style scoped>
.carousel-info {
	margin: 25px auto;
	overflow: hidden;
	background-color: aquamarine;
	height: 100%;
	width: 80%;
}

/* test */

/* test */
</style>
