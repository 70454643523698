<template>
	<div>
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.2659292479207!2d2.3810356155319594!3d48.853139209030665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6720981482053%3A0x1abbd741c8b8367b!2sLes%20Belles%20Plantes!5e0!3m2!1sfr!2sfr!4v1648138416278!5m2!1sfr!2sfr"
			width="600"
			height="450"
			style="border: 0"
			allowfullscreen=""
			loading="lazy"
		></iframe>
	</div>
</template>
<script>
export default {
	name: "MapInfo",
};
</script>

<style scoped>
iframe {
	height: 400px;
	width: 85%;
}
</style>
